import { PrintTemplateComponent } from './../../../../libs/common/components/print/print-template/print-template.component';
import { PrintReceiptComponent } from '@otrack-lib/common/components/print/printreceipt/printreceipt.component';
import { PrintOrderComponent } from '@otrack-lib/common/components/print/printorder/printorder.component';
import { PrintOrderTemplate1Component } from '@otrack-lib/common/components/print/printorder-template1/printorder-template1.component';
import { PrintPaymentComponent } from '@otrack-lib/common/components/print/printpayment/printpayment.component';
import { CompanyResolver } from '@otrack-lib/core/resolvers/company-resolver.service';
import { GlobalAuthGuard } from '@otrack-lib/core/guards/global-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, ExtraOptions, RouterModule } from '@angular/router';
import { AuthComponent } from './common/layout/auth/auth.component';
import { PrintComponent } from '@otrack-lib/common/components/print/print.component';
import { NewOrderGuard } from './services/guards/new-order-guard.service';

const routes: Routes = [
  {
    path: 'print',
    canActivate: [GlobalAuthGuard],
    resolve: { cres: CompanyResolver },
    component: PrintComponent,
    children: [
      {
        path: 'invoice/:data',
        component: PrintOrderComponent
      },
      {
        path: 'template_detail/:data',
        component: PrintOrderTemplate1Component
      },
      {
        path: 'template/:data',
        component: PrintTemplateComponent
      },
      {
        path: 'salesorder/:data',
        component: PrintOrderComponent
      },
      {
        path: 'creditmemo/:data',
        component: PrintOrderComponent
      },
      {
        path: 'purchaseorder/:data',
        component: PrintOrderComponent
      },
      {
        path: 'purchasebill/:data',
        component: PrintOrderComponent // TODO
      },
      {
        path: 'payment/:data',
        component: PrintPaymentComponent
      },
      {
        path: 'receipt/:data',
        component: PrintReceiptComponent
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    canActivate: [GlobalAuthGuard],
    resolve: { cres: CompanyResolver },
    children: [
      // {
      //   path: 'auth',
      //   canActivate: [RedirectAuthGuard],
      //   loadChildren: './modules/authentication/auth.module#AuthModule'
      // },
      {
        path: 'home',
        // canActivate: [AuthGuard],
        loadChildren: './modules/home/home.module#HomeModule'
      },
      {
        path: 'product',
        // canActivate: [AuthGuard],
        loadChildren: './modules/product/product.module#ProductModule'
      },
      {
        path: 'customer-list',
        // canActivate: [AuthGuard],
        loadChildren: './modules/customer-list/customer-list.module#CustomerListModule'
      },
      {
        path: 'customer',
        // canActivate: [AuthGuard],
        loadChildren: './modules/customer/customer.module#CustomerModule'
      },
      {
        path: 'expenses',
        loadChildren: './modules/expense/expense.module#ExpenseModule'
      },
      {
        path: 'vendor',
        // canActivate: [AuthGuard],
        loadChildren: './modules/vendor/vendor.module#VendorModule'
      },
      {
        path: 'order',
       // canActivate: [NewOrderGuard],
        loadChildren: './modules/order/order.module#OrderModule'
      },
      {
        path: 'po',
        //  canActivate: [AuthGuard],
        loadChildren:
          './modules/purchaseorder/purchaseorder.module#PurchaseOrderModule'
      },
      {
        path: 'report',
        //  canActivate: [AuthGuard],
        loadChildren: './modules/report/report.module#ReportModule'
      },
      {
        path: 'payment',
        //  canActivate: [AuthGuard],
        loadChildren: './modules/payment/payment.module#PaymentModule'
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  }
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
