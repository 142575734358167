export const environment = {
  env: 'dev',
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'ce9d77b308c149d5992a80073637e4d5',
  firebase: {
    apiKey: 'AIzaSyCaG31MK3G-5b3ru-U7GoTLXa05mvWrjHE',
    authDomain: 'otrack-dev.firebaseapp.com',
    databaseURL: 'https://otrack-dev.firebaseio.com',
    projectId: 'otrack-dev',
    storageBucket: 'otrack-dev.appspot.com',
    messagingSenderId: '825341143666',
    appId: '1:825341143666:web:177ddbc7f440cc8c84f0eb',
    measurementId: 'G-D6JPQTWW66',
  },
  mode: 'Development Environment',
  apiURL: 'https://api.pinonclick.com',
  appDomain: '.pinonclick.com',  // include dot as prefix
  signupFunction: 'https://app.pinonclick.com/users_signin',
  checkstatusFunction: 'https://app.pinonclick.com/users_checkstatus',
  signoutFunction: 'https://app.pinonclick.com/users_signout',
  loginPageUrl: 'http://auth.pinonclick.com/login',
  sentryDsn: '',
  localToken: ''
};
