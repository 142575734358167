
import { PaymentMethodTypes } from '@otrack-lib/enums';

export class PaymentInformation {
  paymentAmount: number;
  customerId: number;
  paymentMethod: PaymentMethodTypes;
  memo: string;
  chequeNo: string;
  paymentDate: {
    year: number;
    month: number;
    day: number;
  };
}

